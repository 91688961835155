<template>
  <div class="table-inner">
    <resizable-table
      class="my-class"
      table_name="inv-docs"
      :fields="fields"
      :items="items"
      :busy="table_is_busy"
      :inside_card="false"
      :foot_row="false"
      empty_text="Ничего не найдено"
      @row-click="rowClick"
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div class="center bold mt-2">Загрузка...</div>
        </div>
      </template>
      <template #head_id>
        <e-checkbox
          :checked="select_all"
          :position="'center'"
          @click="changeSelectAll"
        />
      </template>

      <template #body_id="data">
        <div
          class="b-statuses"
          :class="{ success: data.item.fixed }"
        ></div>
        <e-checkbox
          :position="'center'"
          :checked="selected.includes(data.value)"
          @click="handleClickCheck(data)"
        />
      </template>

      <template #body_date="data">
        <div class="d-flex flex-column">
          <div class="b-date">
            <span class="d-flex float-left">{{ data.item.number }}</span>
          </div>
          <div class="b-date date mt-1 bottom-descriprion">
            <span class="d-flex float-left">
              {{ data.item.date ? convertDate(data.item.date) : '' }}
            </span>
          </div>
        </div>
      </template>

      <template #body_number="{ item }">
        <span class="d-flex float-left">{{ item.number }}</span>
      </template>

      <template #body_entity="data">
        <div class="b-date">
          <span class="d-flex float-left">{{ data.item.supplierDocument?.id }}</span>
        </div>
        <div class="b-date date mt-1">
          <span class="d-flex float-left">
            {{ data.item.supplierDocument ? convertDate(data.item.supplierDocument?.date) : '–' }}
          </span>
        </div>
      </template>

      <!-- <template #body_supplier="data">
        <div class="b-supplier name cursor">
          {{
            data.item.supplier?.full_name
              ? data.item.supplier?.full_name
              : data.item.supplier?.name
              ? data.item.supplier?.name
              : 'Не указан'
          }}
        </div>
        <div class="b-supplier info">
          ИНН/КПП:
          <span class="ml-2">
            {{ data.item.supplier?.inn ? data.item.supplier.inn : '–' }}/
            {{ data.item.supplier?.kpp ? data.item.supplier.kpp : '–' }}
          </span>
        </div>
      </template> -->

      <template #body_sum="{ item }">
        <span class="d-flex float-right">
          {{ formatPrice(item.price_type === 'purchase' ? item.sum : item.retail_sum) }}
          ₽
        </span>
      </template>

      <template #body_diff="{ item }">
        <span class="d-flex float-right">
          {{ formatPrice(item?.sumTotal ?? 0) }}
          ₽
        </span>
      </template>

      <!-- <template #body_retail_sum="data">
        {{ formatPrice(data.value) }} ₽</template
      > -->

      <!-- <template #body_operation="data">
        <span v-if="data.item.operation?.name === 'income'">Приход</span>
        <span v-else-if="data.item.operation?.name === 'return'">Возврат</span>
        <span v-else-if="data.item.operation?.name === 'write_off'">
          Списание
        </span>
        <span v-else>Не указано</span>
      </template>

      <template #body_status="data">
        <b-badge variant="primary">{{ data.item.status }}</b-badge>
      </template> -->

      <template #body_fixed="data">
        <b-badge :class="{ success: data.item.fixed }">
          {{ data.item.fixed ? 'Проведен' : 'Черновик' }}
        </b-badge>
      </template>

      <template #body_owner="data">
        <span class="d-flex float-left">
          <template v-if="data.item.owner">
            {{ data.item.owner?.first_name }} {{ data.item.owner?.last_name }}
          </template>
          <template v-else>–</template>
        </span>
      </template>

      <template #body_storage="data">
        <div class="b-supplier name cursor">
          <span class="d-flex float-left">
            {{ data.item.storage?.name ?? 'Не указано' }}
          </span>
        </div>
        <div class="bottom-descriprion">
          <span class="d-flex float-left">
            {{ formatAddress(data) }}
          </span>
        </div>
      </template>

      <template #body_comment="data">
        <span class="d-flex float-left">
          {{ data.item.comment ? data.item.comment : '-' }}
        </span>
      </template>
    </resizable-table>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapActions, mapGetters } from 'vuex'
  import ResizableTable from '@/components/ResizableTable'
  import ECheckbox from '@/components/ECheckbox'

  export default {
    components: {
      ResizableTable,
      ECheckbox
    },
    props: {
      selected_arr: {
        type: Array,
        default: () => []
      },
      fields: {
        type: Array,
        default: () => []
      },

      items: {
        type: Array,
        default: () => []
      },

      items_total: {
        type: Number,
        default: 0
      },

      table_is_busy: {
        type: Boolean,
        default: true
      },

      page_type: {
        type: String,
        default: ''
      },
      select_all: {
        type: Boolean,
        default: false
      }
    },

    data: function () {
      return {
        selected: []
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      checked(data) {
        return this.selected.includes(data.value)
      }
    },

    watch: {
      selected_arr() {
        this.selected = this.selected_arr
      },
      select_all(newVal) {
        if (!newVal) {
          this.selected = []
          // this.setSelect()
          this.$emit('changeSelectAll', newVal, this.selected)
          return
        }

        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
        // this.setSelect()
        this.$emit('changeSelectAll', newVal, this.selected)
      }
    },

    mounted() {
      localStorage.setItem('inv-docs_fields', JSON.stringify(this.fields))
      this.clearCurrentDocument()
    },

    methods: {
      ...mapActions({
        clearCurrentDocument: 'operprocess/clearCurrentDocument'
      }),

      changeSelectAll() {
        if (!this.select_all) {
          this.selected = []
          this.$emit('changeSelectAll', true, this.selected)
          return
        }
        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
        this.$emit('changeSelectAll', false, this.selected)
      },

      handleClickCheck(data) {
        if (this.selected.includes(data.value)) {
          this.selected = this.selected.filter((item) => item !== data.value)
        } else {
          this.selected.push(data.value)
        }
        this.setSelect()
      },

      convertDate(date) {
        const dt = new Date(date).getTime() + new Date().getTimezoneOffset() * 60 * 1000

        if (dt) return formatDate(new Date(dt), 'numeric')
      },

      setSelect() {
        this.$emit('select', this.selected)
      },

      formatPrice(val) {
        return new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }).format(val)
      },

      formatAddress(data) {
        const address =
          (data.value.address?.city ?? '') +
          ', ' +
          (data.value.address?.street ?? '') +
          ', ' +
          (data.value.address?.house ?? '') +
          ', ' +
          (data.value.address?.apartment ?? '')

        return address.length > 30 ? address.slice(0, 27) + '...' : address
      },

      rowClick(item) {
        this.$router.push({
          name: `operation-process.overhead.inventory.document.edit`,
          params: { id: item.id }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.my-class) th.cursor:not(:nth-child(1)):not(:nth-child(7)) {
    div {
      justify-content: space-between !important;
      display: flex;
      float: left;
    }
    .td-div {
      width: 100%;
    }
    .td-inner {
      width: 100% !important;
      display: flex !important;
      justify-content: space-between !important;
    }
  }
  :deep(.my-class) .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }

  :deep(.my-class) td.text-nowrap {
    position: relative;

    .custom-checkbox {
      width: 100%;
      margin-right: 10px;
    }

    &.custom_checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .b-statuses {
      width: 2px !important;
      height: 32px !important;
      position: absolute;
      left: 0px;
      top: 50%;
      background: #bcbcbc;
      border-radius: 0px 1px 1px 0px;
      transform: translate(0%, -50%);

      &.success {
        background: #00cb91;
      }
    }
  }
  :deep(.my-class) .table-docs {
    min-height: calc(100%) !important;
    padding-bottom: 0 !important;
  }
  .table-inner {
    display: flex;
    flex: 1;
  }

  :deep(.my-class) #table-docs {
    tr {
      cursor: pointer;
    }
  }

  :deep(.my-class) th {
    padding: 10px 16px !important;
  }

  :deep(.my-class) .inv-docs {
    max-height: 0 !important;
  }

  .bottom-descriprion {
    font-size: 12px;
    color: #888888;
  }
  @media (max-height: 700px) {
    :deep(.my-class) .table-docs {
      max-height: 100% !important;
    }
  }
</style>
