var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticStyle: {
      "display": "flex",
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('inventory-header', {
    on: {
      "date_range": _vm.updateDateRange
    }
  }), _c('div', {
    staticClass: "b-table__content"
  }, [_c('inventory-table', {
    attrs: {
      "select_all": _vm.select_all,
      "fields": _vm.table.fields,
      "items_total": _vm.table.total,
      "items": _vm.documents,
      "table_is_busy": _vm.table.isBusy,
      "selected_arr": _vm.selected
    },
    on: {
      "select": _vm.setSelected,
      "changeSelectAll": _vm.changeSelectAll
    }
  })], 1), _vm.selected.length >= 1 ? _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "inv_documents",
      "clear_selected": _vm.clearSelected
    },
    on: {
      "remove_items": _vm.remove_items,
      "print_doc": _vm.print_doc
    }
  }) : _vm._e(), _c('pagination', {
    attrs: {
      "total": _vm.table.total,
      "table_name": "inv-docs"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }