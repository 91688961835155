var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-inner"
  }, [_c('resizable-table', {
    staticClass: "my-class",
    attrs: {
      "table_name": "inv-docs",
      "fields": _vm.fields,
      "items": _vm.items,
      "busy": _vm.table_is_busy,
      "inside_card": false,
      "foot_row": false,
      "empty_text": "Ничего не найдено"
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center mt-2"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', {
          staticClass: "center bold mt-2"
        }, [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all,
            "position": 'center'
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-statuses",
          class: {
            success: data.item.fixed
          }
        }), _c('e-checkbox', {
          attrs: {
            "position": 'center',
            "checked": _vm.selected.includes(data.value)
          },
          on: {
            "click": function ($event) {
              return _vm.handleClickCheck(data);
            }
          }
        })];
      }
    }, {
      key: "body_date",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', {
          staticClass: "b-date"
        }, [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(_vm._s(data.item.number))])]), _c('div', {
          staticClass: "b-date date mt-1 bottom-descriprion"
        }, [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(data.item.date ? _vm.convertDate(data.item.date) : '') + " ")])])])];
      }
    }, {
      key: "body_number",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(_vm._s(item.number))])];
      }
    }, {
      key: "body_entity",
      fn: function (data) {
        var _data$item$supplierDo, _data$item$supplierDo2;

        return [_c('div', {
          staticClass: "b-date"
        }, [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(_vm._s((_data$item$supplierDo = data.item.supplierDocument) === null || _data$item$supplierDo === void 0 ? void 0 : _data$item$supplierDo.id))])]), _c('div', {
          staticClass: "b-date date mt-1"
        }, [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(data.item.supplierDocument ? _vm.convertDate((_data$item$supplierDo2 = data.item.supplierDocument) === null || _data$item$supplierDo2 === void 0 ? void 0 : _data$item$supplierDo2.date) : '–') + " ")])])];
      }
    }, {
      key: "body_sum",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.price_type === 'purchase' ? item.sum : item.retail_sum)) + " ₽ ")])];
      }
    }, {
      key: "body_diff",
      fn: function (_ref3) {
        var _item$sumTotal;

        var item = _ref3.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice((_item$sumTotal = item === null || item === void 0 ? void 0 : item.sumTotal) !== null && _item$sumTotal !== void 0 ? _item$sumTotal : 0)) + " ₽ ")])];
      }
    }, {
      key: "body_fixed",
      fn: function (data) {
        return [_c('b-badge', {
          class: {
            success: data.item.fixed
          }
        }, [_vm._v(" " + _vm._s(data.item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }, {
      key: "body_owner",
      fn: function (data) {
        var _data$item$owner, _data$item$owner2;

        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [data.item.owner ? [_vm._v(" " + _vm._s((_data$item$owner = data.item.owner) === null || _data$item$owner === void 0 ? void 0 : _data$item$owner.first_name) + " " + _vm._s((_data$item$owner2 = data.item.owner) === null || _data$item$owner2 === void 0 ? void 0 : _data$item$owner2.last_name) + " ")] : [_vm._v("–")]], 2)];
      }
    }, {
      key: "body_storage",
      fn: function (data) {
        var _data$item$storage$na, _data$item$storage;

        return [_c('div', {
          staticClass: "b-supplier name cursor"
        }, [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((_data$item$storage$na = (_data$item$storage = data.item.storage) === null || _data$item$storage === void 0 ? void 0 : _data$item$storage.name) !== null && _data$item$storage$na !== void 0 ? _data$item$storage$na : 'Не указано') + " ")])]), _c('div', {
          staticClass: "bottom-descriprion"
        }, [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(_vm.formatAddress(data)) + " ")])])];
      }
    }, {
      key: "body_comment",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s(data.item.comment ? data.item.comment : '-') + " ")])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }