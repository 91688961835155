<template>
  <section style="display: flex; flex: 1">
    <!-- <div class="content__header">Инвентаризация</div> -->
    <div class="b-table__wrapper">
      <inventory-header @date_range="updateDateRange" />
      <div class="b-table__content">
        <!--        <documents-table-filter-->
        <!--          ref="doc_table_filter"-->
        <!--          @handler_pagination="handlerSearch"-->
        <!--          @clear="clearSearchStr"-->

        <inventory-table
          :select_all="select_all"
          :fields="table.fields"
          :items_total="table.total"
          :items="documents"
          :table_is_busy="table.isBusy"
          :selected_arr="selected"
          @select="setSelected"
          @changeSelectAll="changeSelectAll"
        />
      </div>
      <table-navbar
        v-if="selected.length >= 1"
        :items="selected"
        items_name="inv_documents"
        :clear_selected="clearSelected"
        @remove_items="remove_items"
        @print_doc="print_doc"
      />
      <pagination
        :total="table.total"
        table_name="inv-docs"
        @change_pagination="handlerPagination"
      />
    </div>
  </section>
</template>

<script>
  import InventoryHeader from '@/views/operational-processes/components/inventory/InventoryHeader'
  import InventoryTable from '@/views/operational-processes/components/inventory/InventoryTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'Documents',
    components: { InventoryHeader, InventoryTable, Pagination, TableNavbar },

    apollo: {
      InventarisationHeads: {
        query: require('./gql/InventoryHeads.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch?.__typename === 'BranchClassifModel' ? null : this.currentBranch.id,
            documentPagination: {
              period: {
                begin: this.data_range[0] ?? null,
                end: this.data_range[1] ?? null
              },
              skip: this.getPagination?.['inv-docs']?.skip,
              take: this.getPagination?.['inv-docs'].take,
              search: this.getPagination?.['inv-docs'].search
            },
            group: this.currentBranch?.__typename === 'BranchClassifModel' ? this.currentBranch.id : null
          }
        },
        result({ data }) {
          this.selected = []
          this.table.isBusy = false
          this.table.total = data.InventarisationHeads?.total ?? 0
          this.documents = data.InventarisationHeads?.documents ?? []

          this.table.isBusy = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.table.isBusy = false
        },
        watchLoading(isLoad) {
          this.table.isBusy = isLoad
        }
      }
    },

    data: function () {
      return {
        table: {
          fields: [
            {
              key: 'id',
              checked: true,
              label: '',
              class: 'center',
              width: 55,
              sortable: false
            },
            {
              checked: true,
              key: 'date',
              label: 'Дата',
              width: 100,
              sortable: false
            },
            {
              checked: true,
              key: 'number',
              label: '№',
              width: 100,
              sortable: false
            },
            {
              checked: true,
              key: 'entity',
              label: 'Наше юрлицо',
              width: 150,
              sortable: true
            },
            {
              checked: true,
              key: 'sum',
              label: 'Итого, ₽',
              width: 100,
              sortable: true,
              formatter: (value) => +value.toFixed(2) || 0
            },
            {
              checked: true,
              key: 'diff',
              label: 'Итого разница,₽',
              width: 100,
              sortable: true
            },
            {
              checked: true,
              key: 'fixed',
              label: 'Статус',
              class: 'center',
              width: 143,
              sortable: true
            },
            {
              checked: true,
              key: 'owner',
              label: 'Менеджер',
              width: 188,
              sortable: true
            },
            {
              checked: true,
              key: 'storage',
              label: 'Место хранения',
              width: 165,
              sortable: true
            },
            {
              checked: true,
              key: 'comment',
              label: 'Комментарий',
              width: 150
            }
          ],
          total: 0,
          isBusy: true
        },
        documents: [],
        selected: [],
        select_all: false,
        data_range: []
      }
    },

    methods: {
      ...mapActions({ setPagination: 'settings/set_pagination' }),
      handlerSearch(search) {
        this.setPagination({
          ...this.getPagination,
          ['inv-docs']: {
            ...this.getPagination?.['inv-docs'],
            search
          }
        })
      },
      changeSelectAll(select_all, selected) {
        this.select_all = select_all
        this.selected = selected
      },
      clearSelected() {
        this.selected = []
        this.select_all = false
      },
      clearSearchStr() {
        this.setPagination({
          ...this.getPagination,
          ['inv-docs']: {
            ...this.getPagination?.['inv-docs'],
            search: ''
          }
        })
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) {
          this.setPagination({
            ...this.getPagination,
            ['inv-docs']: {
              ...this.getPagination?.['inv-docs'],
              skip: Number((current_page - 1) * take)
            }
          })
        }
        if (take) {
          this.setPagination({
            ...this.getPagination,
            ['inv-docs']: {
              ...this.getPagination?.['inv-docs'],
              take
            }
          })
        }
      },

      updateDateRange(date) {
        this.table.isBusy = true
        this.data_range = date
        this.$apollo.queries.InventarisationHeads.refresh()
      },

      setSelected(data) {
        this.selected = data
      },

      print_doc() {},

      async remove_items(items) {
        await this.$apollo.mutate({
          mutation: require('./gql/RemoveInventoryHead.graphql'),
          variables: {
            input: { ids: items }
          }
        })
        await this.$apollo.queries.InventarisationHeads.refetch()
        this.selected = []
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) this.selected = []

        this.documents.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },

    mounted() {
      this.$apollo.queries.InventarisationHeads.refetch()
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getPagination: 'settings/getPagination'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .b-table__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 0 !important;
  }

  .b-table__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
