<template>
  <div class="b-table__header">
    <div class="b-table__filter">
      <date-picker
        v-model="filter_date_range"
        :clearable="false"
        type="daterange"
        :open="datepicker_open"
        :editable="false"
        format="dd.MM.yyyy"
        class="elm-calendar datepicker-range hidden-picker"
        placeholder="Рабочий период"
        @on-change="setPeriod({ period: 'custom' })"
      />
      <b-dropdown
        no-caret
        variant="none"
      >
        <template #button-content>
          <div
            style="
              background: white;
              padding: 8px 10px;
              display: flex;
              flex-direction: row;
              height: 32px;
              border: 1px solid #bcbcbc;
            "
          >
            <span style="color: black">{{ getPeriod }}</span>
            <img
              src="/img/icons/calendar-dark.svg"
              alt="calendar"
              class="ml-2"
            />
          </div>
        </template>
        <b-dropdown-item
          class="sm-dropdown"
          @click.stop="datepicker_open = true"
        >
          Указать период
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'today' })"
        >
          Сегодня
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'yesterday' })"
        >
          Вчера
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'week' })"
        >
          Текущая неделя
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lweek' })"
        >
          Прошлая неделя
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'month' })"
        >
          Текущий месяц
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lmonth' })"
        >
          Прошлый месяц
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lyear' })"
        >
          Последние 365 дней
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: null })"
        >
          Все время
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="b-table__actions">
      <b-button
        class="btn-brand cursor"
        variant="primary"
        @click="createDocument"
      >
        <img
          src="/img/icons/btn-plus.svg"
          class="mr"
          alt=""
        />
        Добавить документ
      </b-button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    props: {
      page_type: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      filter_date_range: [],
      selected_type: null,
      datepicker_open: false,
      moddate: new Date()
    }),

    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange',
        currentBranch: 'settings/getCurrentBranch'
      }),
      getPeriod() {
        switch (this.selected_type) {
          case 'today': {
            return 'Сегодня'
          }
          case 'yesterday': {
            return 'Вчера'
          }
          case 'week': {
            return 'Текущая неделя'
          }
          case 'lweek': {
            return 'Прошлая неделя'
          }
          case 'month': {
            return 'Текущий месяц'
          }
          case 'lmonth': {
            return 'Прошлый месяц'
          }
          case 'lyear': {
            return 'Последние 365 дней'
          }
          case 'custom': {
            const start = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[0] ? new Date(this.filter_date_range[0]) : new Date()
            )
            const end = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[1] ? new Date(this.filter_date_range[1]) : new Date()
            )
            return start + ' - ' + end
          }
          default: {
            return 'За все время'
          }
        }
      }
    },

    mounted() {
      if (this.dateRange) {
        this.filter_date_range = this.dateRange.date
        this.setPeriod({ period: this.dateRange.type })
        this.updateDateRange(this.filter_date_range)
      }
    },

    methods: {
      ...mapActions({
        setDateRange: 'operprocess/setDateRange'
      }),

      createDocument() {
        this.$apollo
          .mutate({
            mutation: require(`../../pages/inventory/gql/CreateInventoryDocument.graphql`),
            variables: {
              branch: this.currentBranch.id
            }
          })
          .then(({ data }) => {
            const id = R.pathOr([], ['CreateInventarisationDocument', 'id'], data)

            this.$router.push({
              name: `operation-process.overhead.inventory.document.edit`,
              params: { id }
            })
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При создании документа произошла ошибка. Попробуйте еще раз`)
          })
        localStorage.setItem('newDocHeader', 'true')
      },

      setPeriod({ period }) {
        switch (period) {
          case 'today': {
            this.filter_date_range[0] = new Date(new Date().setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date().setHours(23, 59, 59))
            this.selected_type = period
            break
          }
          case 'yesterday': {
            const today = new Date()
            this.filter_date_range[0] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(23, 59, 59))
            this.selected_type = period
            break
          }
          case 'week': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() + 1
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            this.selected_type = period
            break
          }
          case 'lweek': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() - 6
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            this.selected_type = period
            break
          }
          case 'month': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth(), 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth() + 1, 0, 23, 59, 59)
            this.selected_type = period
            break
          }
          case 'lmonth': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 1, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            this.selected_type = period
            break
          }
          case 'lyear': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear() - 1, curr.getMonth(), curr.getDay(), 0, 0, 0)
            this.filter_date_range[1] = new Date()
            this.selected_type = period
            break
          }
          case 'custom': {
            this.datepicker_open = false
            this.moddate = new Date()
            // const today = new Date()
            // if (isMount && today.getDate() !== new Date(this.getPeriod.moddate).getDate()) {
            //   this.setPeriod({ period: null })
            // }
            this.selected_type = period
            break
          }
          default: {
            this.filter_date_range[0] = null
            this.filter_date_range[1] = null
            this.selected_type = period
            break
          }
        }
        this.updateDateRange()
      },

      updateDateRange() {
        this.setDateRange({
          type: this.selected_type,
          date: this.filter_date_range,
          moddate: this.moddate
        })
        this.$emit('date_range', this.filter_date_range)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .sm-dropdown > a {
      padding: 4px 12px !important;
    }

    .hidden-picker > .ivu-date-picker-rel {
      max-width: 0px !important;
    }

    .hidden-picker > div > div > span {
      display: none;
    }

    .hidden-picker > div > div > input {
      display: none;
    }

    .e_button {
      padding: 6px 12px !important;
    }
  }

  .hidden-picker {
    max-width: 0px !important;
  }

  .b-table__header {
    display: flex;
    justify-content: space-between;
  }
  .datepicker-range {
    min-width: 0 !important;
  }

  .b-table__header {
    justify-content: space-between;
  }
  .datepicker-range {
    width: 0;
  }
</style>
